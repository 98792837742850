<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body class="mb-4">
        <b-card-body class="p-3">
          <h5 class="card-title mb-0">Calendars</h5>
        </b-card-body>
        <div class="p-3 d-flex align-items-center">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="9"
            label-size="sm"
            label-class="fw-medium"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
          <div class="ml-auto">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
        <b-table
          responsive
          class="mb-0"
          head-variant="light"
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
        >
          <template #cell(actions)="data">
            <b-button variant="light" class="sm" @click="editCal(data.item.id)">
              <feather type="edit" class="feather-sm"></feather>
            </b-button>
            <b-button variant="danger" class="sm" style="margin:3px" autofocus @click="deleteCal(data.item.id)">
              <feather type="trash" class="feather-sm" ></feather>
            </b-button>
         </template>
        </b-table>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
//import { mapActions } from 'vuex';
export default {
  name: "Calendars",

  data: () => ({
    page: {
      title: "PaginationTable",
    },
    fields:["id","name","start_date","end_date","actions"],
    items: [],
    totalRows: 1,
    currentPage: 1,
    perPage: 5,
    pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
  }),
  components: {},
  computed:{
    ...mapState({
      authId:(state) => state.auth.authId
    })
  },
  mounted() {
    this.loadCalendars();
    this.totalRows = this.items.length;
  },
  methods: {
    ...mapActions(["getCalendarsByUser","deleteCalendar"]),
    loadCalendars() {
      this.getCalendarsByUser(this.authId).then((res) => {
        this.items = res;
      });
    },
    deleteCal(id){
      this.deleteCalendar({'id': id}).then(()=>{
        this.loadCalendars();
      })
    },
    editCal(id){
      this.$router.push({ name: "UpdateCalendar", params:{ id: id} })
    }
  },
};
</script>